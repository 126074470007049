<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li><a href="javascript:void(0);">About Us</a></li>
        </ul>
      </div>
    </div>
    <section class="section--about ps-page--business">
      <div class="container">
        <!-- <div class="about__Suyee Family Marketting"> -->
          <!-- <div class="about__header"> -->
            <h3 class="page__title pb-4">About Suyee Family Marketing</h3>
            <p class="about__subtilte">
              The Suyee Family Marketing is an events center offering the
              charm of a vintage barn with the accommodations of a fresh built
              space. Featuring Bride & Groom quarters, restrooms, and a warming
              kitchen for outside caterers, we are prepared for all of your
              event needs.
            </p>
            <p class="about__des">
              We are breaking ground on The Suyee Family Marketing in
              May 2020 and are anticipating a Grand Opening in Fall 2020. The
              Farmhouse Barn land is available for weddings and events before
              the Grand Opening.
            </p>
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>
  </main>
</template>

